"use client";

import Link from "next/link";
import { HiUser } from "react-icons/hi";
import { BiDrink } from "react-icons/bi";
import { TiArrowBackOutline } from "react-icons/ti";
import { MdOutlineCalendarMonth, MdOutlineArticle } from "react-icons/md";
import { TfiAngleDown, TfiAngleUp } from "react-icons/tfi";
import { usePathname, useRouter } from "next/navigation";
import { format, subDays } from "date-fns";
import { useState, useEffect } from "react";
import CustomCalendar from "./CustomCalendar";
import { useDate } from "../_context/DateContext";
import { useSidebar } from "../_context/SidebarContext";

// Navigation links
const navLinks = [
  {
    name: "Cocktails",
    href: "/",
    icon: <MdOutlineCalendarMonth className="h-5 w-5 text-primary-600" />,
  },
  {
    name: "Barkeeping",
    href: "/barkeeping",
    icon: <BiDrink className="h-5 w-5 text-primary-600" />,
  },
  {
    name: "Mixology Blog", // New Blog link
    href: "/blog",
    icon: <MdOutlineArticle className="h-5 w-5 text-primary-600" />, // Icon for Blog
  },
];

// Navigation component
function Navigation({ cocktails }) {
  const pathname = usePathname();
  const router = useRouter();

  // Bestimme das heutige und gestrige Datum
  const today = format(new Date(), "yyyy-MM-dd");
  const yesterday = format(subDays(new Date(), 1), "yyyy-MM-dd");

  // Überprüfe, ob der Benutzer sich die Seite für den Cocktail des gestrigen Tages ansieht
  const isViewingYesterday = pathname.includes(`/cocktails/date/${yesterday}`);

  // Basierend darauf, ob der Benutzer die gestrige Seite ansieht, stelle den dynamischen Link ein
  const dynamicLink = isViewingYesterday
    ? {
        name: "Cocktail des Tages",
        href: `/cocktails/date/${today}`,
        icon: <MdOutlineCalendarMonth className="h-5 w-5 text-primary-600" />,
      }
    : {
        name: "Cocktail von Gestern",
        href: `/cocktails/date/${yesterday}`,
        icon: <TiArrowBackOutline className="h-5 w-5 text-primary-600" />,
      };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { selectedDate, setSelectedDate } = useDate();
  const { closeSidebar } = useSidebar();

  const isMobile = () => window.innerWidth <= 768;

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  const handleLinkClick = (href, isCocktailsLink = false) => {
    if (isCocktailsLink) {
      toggleCalendar();
    } else {
      router.push(href);

      if (isMobile()) closeSidebar();
    }
  };

  const handleDateSelect = (date) => {
    const selectedCocktail = cocktails.find(
      (cocktail) => cocktail.date === format(date, "yyyy-MM-dd")
    );
    if (selectedCocktail) {
      setSelectedDate(date);
      router.push(
        `/cocktails/name/${encodeURIComponent(selectedCocktail.name)}`
      );
    }

    if (isMobile()) closeSidebar();
  };
  useEffect(() => {
    if (pathname.includes("/cocktails/date/")) {
      const dateFromPath = pathname.split("/").pop();
      setSelectedDate(new Date(dateFromPath));
    } else {
      setSelectedDate(new Date());
    }
  }, [pathname, setSelectedDate]);

  return (
    <nav className="h-full flex flex-col pt-8">
      <ul className="flex flex-col gap-4 flex-grow justify-start items-center px-4 lg:px-6">
        {navLinks.map((link) => (
          <li key={link.name} className="w-full mt-4">
            <div className="relative">
              <Link
                className={`py-3 px-5 hover:bg-primary-900 hover:text-primary-100 transition-colors flex items-center gap-4 font-semibold text-primary-200 ${
                  pathname === link.href
                    ? "bg-primary-900 text-primary-100"
                    : ""
                } w-full text-center justify-center`}
                href={link.href}
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick(link.href, link.name === "Cocktails");
                }}
              >
                {link.icon}
                <span>{link.name}</span>
                {link.name === "Cocktails" &&
                  (isCalendarOpen ? (
                    <TfiAngleUp className="ml-2 h-5 w-5" />
                  ) : (
                    <TfiAngleDown className="ml-2 h-5 w-5" />
                  ))}
              </Link>
              {link.name === "Cocktails" && isCalendarOpen && (
                <div
                  className={`transition-all duration-500 ease-in-out ${
                    isCalendarOpen ? "max-h-screen" : "max-h-0"
                  } overflow-hidden`}
                >
                  <div className="p-4 w-full">
                    <CustomCalendar
                      cocktails={cocktails}
                      onDateSelect={handleDateSelect}
                    />
                  </div>
                </div>
              )}
            </div>
          </li>
        ))}

        {/* Dynamischer Link für "Cocktail des Tages" oder "Cocktail von Gestern" */}
        <li key={dynamicLink.name} className="w-full">
          <Link
            className={`py-3 px-5 hover:bg-primary-900 hover:text-primary-100 transition-colors flex items-center gap-4 font-semibold text-primary-200 ${
              pathname === dynamicLink.href
                ? "bg-primary-900 text-primary-100"
                : ""
            } w-full text-center justify-center`}
            href={dynamicLink.href}
            onClick={(e) => {
              e.preventDefault();
              setSelectedDate(
                new Date(
                  dynamicLink.name === "Cocktail des Tages" ? today : yesterday
                )
              );
              handleLinkClick(dynamicLink.href);
            }}
          >
            {dynamicLink.icon}
            <span>{dynamicLink.name}</span>
          </Link>
        </li>

        <li className="mt-auto w-full flex flex-col items-center text-center relative">
          <div className="group">
            <Link
              href="/admin/login"
              className="py-3 px-5 hover:bg-primary-900 hover:text-primary-100 transition-colors flex items-center justify-center gap-4 font-semibold text-primary-200 relative"
              onClick={(e) => {
                e.preventDefault();
                setSelectedDate(new Date());
                handleLinkClick("/admin/login");
              }}
            >
              <HiUser className="h-6 w-6 text-primary-600" />
            </Link>

            <span className="opacity-0 group-hover:opacity-100 bg-primary-900 text-amber-900 dark:text-slate-200 text-xs rounded-md px-2 py-1 absolute bottom-full mb-1 transition-opacity duration-300 whitespace-nowrap">
              Zugang für den Admin
            </span>
          </div>
        </li>
        <li className="mt-2 w-full text-center">
          <Link
            href="/privatepolicy"
            className="text-xs text-primary-200 hover:text-primary-100 transition-colors"
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick("/privatepolicy");
            }}
          >
            Datenschutz
          </Link>
          <span className="mx-2 text-primary-200">|</span>
          <Link
            href="/imprint"
            className="text-xs text-primary-200 hover:text-primary-100 transition-colors"
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick("/imprint");
            }}
          >
            Impressum
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
