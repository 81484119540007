"use client";

import Navigation from "./Navigation";
import { useSidebar } from "../_context/SidebarContext";

export default function Sidebar({ cocktails }) {
  const { isSidebarOpen, toggleSidebar } = useSidebar();

  return (
    <div className="lg:w-2/5 lg:max-w-md lg:fixed lg:right-0 lg:top-0 lg:bottom-0 z-40">
      <aside
        className={`fixed lg:relative lg:flex lg:flex-col top-0 right-0 bg-amber-100 dark:bg-slate-900 text-amber-900 dark:text-slate-100 h-screen shadow-lg w-80 lg:w-full pt-20 lg:ml-8 z-40 transition-transform duration-300 transform ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        } lg:translate-x-0`}
      >
        <button
          onClick={toggleSidebar}
          className="absolute top-4 right-4 p-2 bg-primary-600 text-white rounded-md lg:hidden"
        >
          Toggle
        </button>
        <div className="flex-1 overflow-y-auto p-4">
          <Navigation cocktails={cocktails} />
        </div>
      </aside>
    </div>
  );
}
