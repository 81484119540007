"use client";

import { useState, useEffect } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
  subMonths,
  addMonths,
} from "date-fns";
import { de } from "date-fns/locale";
import { useRouter } from "next/navigation";
import Image from "next/image";
import { useDate } from "../_context/DateContext";
import { useSidebar } from "../_context/SidebarContext";

const daysOfWeek = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

const CustomCalendar = () => {
  const { setSelectedDate } = useDate();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const { closeSidebar } = useSidebar();
  const [cocktails, setCocktails] = useState([]);
  const router = useRouter();

  const firstDayOfMonth = startOfMonth(currentMonth);
  const lastDayOfMonth = endOfMonth(currentMonth);
  const days = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  });

  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const handleDayClick = async (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");

    try {
      const response = await fetch(`/api/cocktails?date=${formattedDate}`);
      const data = await response.json();

      if (data.cocktail) {
        setSelectedDate(date);
        router.push(`/cocktails/${encodeURIComponent(data.cocktail.name)}`);
      } else {
        setSelectedDate(date);
        router.push(`/cocktails/date/${formattedDate}`);
      }
    } catch (error) {
      console.error("Error fetching cocktail for selected date:", error);
      setSelectedDate(date);
      router.push(`/cocktails/date/${formattedDate}`);
    }

    closeSidebar();
  };
  useEffect(() => {
    const fetchCocktails = async () => {
      const res = await fetch("/api/cocktails");
      const data = await res.json();
      setCocktails(data.cocktails);
    };

    fetchCocktails();
  }, []);

  const today = new Date();

  // Berechnung des Start-Offsets für die korrekte Ausrichtung
  const startDay = firstDayOfMonth.getDay();
  const emptyDays = Array.from({ length: startDay }, (_, i) => (
    <div key={`empty-${i}`} className="p-2 bg-transparent"></div>
  ));

  return (
    <div className="calendar p-4 bg-amber-50 dark:bg-slate-900 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={handlePrevMonth}
          className="text-amber-700 dark:text-slate-200 hover:text-amber-900 dark:hover:text-slate-50"
        >
          &lt;
        </button>
        <h2 className="text-xl text-amber-900 dark:text-slate-50">
          {format(currentMonth, "MMMM yyyy", { locale: de })}
        </h2>
        <button
          onClick={handleNextMonth}
          className="text-amber-700 dark:text-slate-200 hover:text-amber-900 dark:hover:text-slate-50"
        >
          &gt;
        </button>
      </div>
      <div className="grid grid-cols-7">
        {daysOfWeek.map((day, index) => (
          <div
            key={index}
            className="p-2 bg-amber-800 dark:bg-slate-800 text-white text-center"
          >
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7">
        {emptyDays}
        {days.map((day, index) => {
          const formattedDate = format(day, "yyyy-MM-dd");
          const cocktail = cocktails.find((c) => c.date === formattedDate);
          const isToday = isSameDay(day, today);
          const isFuture = day > today;

          return (
            <div
              key={index}
              className={`calendar-cell p-2 border dark:border-slate-700 ${
                isToday
                  ? "bg-amber-300 dark:bg-slate-700"
                  : "bg-amber-100 dark:bg-slate-800"
              } ${
                isFuture ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={() => !isFuture && handleDayClick(day)}
            >
              {cocktail && !isFuture ? (
                <div className="block text-center text-amber-500 dark:text-slate-400 hover:text-amber-700 dark:hover:text-slate-200">
                  <div>{cocktail.name}</div>
                  {cocktail.imageUrl && (
                    <Image
                      src={cocktail.imageUrl}
                      alt={cocktail.name}
                      width={48}
                      height={48}
                      className="mx-auto mt-1 object-cover rounded-full"
                    />
                  )}
                </div>
              ) : (
                <span className="day text-amber-900 dark:text-slate-400 hover:text-amber-700 dark:hover:text-slate-200">
                  {format(day, "d")}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomCalendar;
