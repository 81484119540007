"use client";
import { useContext, useEffect } from "react";
import Link from "next/link";
import { MdOutlineLightMode, MdOutlineDarkMode } from "react-icons/md";
import { CgMenuRound, CgCloseO } from "react-icons/cg";
import ThemeContext from "../_context/ThemeContext";
import { useDate } from "../_context/DateContext";
import { useSidebar } from "../_context/SidebarContext";
import { format, isToday } from "date-fns";
import { usePathname } from "next/navigation";

function Header() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const { selectedDate, setSelectedDate } = useDate();
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const pathname = usePathname();

  // Funktion zur dynamischen Anpassung des Titels
  const getTitle = () => {
    if (selectedDate && !isToday(new Date(selectedDate))) {
      return `Cocktail des Tages`;
    }
    return "Cocktail des Tages";
  };

  const getFormattedDate = () => {
    if (selectedDate && !isToday(new Date(selectedDate))) {
      return format(new Date(selectedDate), "dd.MM.yyyy");
    }
    return null;
  };

  useEffect(() => {
    setSelectedDate(new Date());
  }, [pathname, setSelectedDate]);

  return (
    <header className="bg-amber-100 dark:bg-slate-900 text-amber-900 dark:text-slate-100 shadow-md py-2 sm:py-4 px-8 fixed top-0 left-0 w-full z-50 border-b border-primary-800 dark:border-slate-800">
      <div className="flex justify-between items-center">
        <button
          onClick={toggleDarkMode}
          className="text-xl sm:text-2xl border-none bg-amber-100 dark:bg-slate-900 text-amber-900 dark:text-slate-100 lg:order-1"
        >
          {darkMode ? <MdOutlineLightMode /> : <MdOutlineDarkMode />}
        </button>

        <div className="flex-1 flex flex-col items-center justify-center order-2 relative overflow-hidden">
          <Link
            href="/"
            className="flex flex-col items-center gap-0.5 sm:gap-0.5"
          >
            <div className="overflow-hidden w-full">
              <hp className="text-sm sm:text-base font-semibold text-amber-900 dark:text-slate-100 animate-flicker">
                Entdecken Sie jeden Tag einen neuen
              </hp>
            </div>

            <h1 className="text-lg sm:text-2xl md:text-3xl font-bold">
              {getTitle()}
            </h1>

            {getFormattedDate() && (
              <p className="text-xs sm:text-sm text-amber-900 dark:text-slate-100 italic">
                {getFormattedDate()}
              </p>
            )}
          </Link>
        </div>

        <button
          onClick={toggleSidebar}
          className="text-xl sm:text-2xl lg:hidden focus:outline-none order-3 bg-amber-100 dark:bg-slate-900 text-amber-900 dark:text-slate-100"
        >
          {isSidebarOpen ? <CgCloseO /> : <CgMenuRound />}
        </button>
      </div>
    </header>
  );
}

export default Header;
